<template>
  <v-container fluid>
    <patient-detail :patientGuid="header.patientGuid"></patient-detail>
    <v-card class="pa-5 mt-3">
      <!-- <v-card-subtitle> -->
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              {{ $t("tests.barcode") }}
              :

              <v-chip label outlined color="" v-text="header.barcode" />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              {{ $t("tests.testCode") }}
              :
              <v-chip label outlined color="" v-text="header.testCode" />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              {{ $t("tests.testName") }}
              :
              <v-chip label outlined color="" v-text="header.testName" />
            </v-col>

            <v-col cols="12" md="4" sm="12">
              {{ $t("date") }}
              :
              <v-chip label outlined color="">
                {{ header.dateReg | moment("YYYY/MM/DD hh:mm A") }}
              </v-chip>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              {{ $t("tests.sampleType") }}
              :
              <v-chip label outlined color="" v-text="header.sampleType" />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              {{ $t("tests.sampleCode") }}
              :
              <v-chip label outlined color="" v-text="header.sampleCode" />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-select
                :class="header.useTemplate ? '' : 'd-none'"
                v-model="header.testTemplateGuid"
                :label="$t('tests.templateName')"
                @change="templateChange"
                :items="testTemplates"
                clearable
                item-text="templateName"
                item-value="guid"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical />
        <v-col cols="auto">
          {{ $t("tests.changeDone") }} :
          <v-chip label color="" class="ma-2 px-2">
            <span class="mx-1">
              {{ header.isDone ? $t("tests.done") : $t("tests.notDone") }}
            </span>

            <v-icon :color="header.isDone ? 'success' : 'red darken-2 '">
              {{ header.isDone ? "mdi-check-circle" : "mdi-close-circle" }}
              <!-- mdi-archive-off-outline mdi-close-circle -->
            </v-icon>
            <!-- <v-icon v-else color="success">
              mdi-archive-check-outline mdi-check-circle
            </v-icon> -->
          </v-chip>
          <v-row no-gutters>
            <v-col class="mx-1" v-if="$store.getters.isInRole(96)">
              <v-btn
                block
                outlined
                @click="doneItem"
                :color="header.isDone ? 'red darken-2 white--text' : 'primary'"
              >
                <span v-if="header.isDone">{{ $t("tests.removeDone") }}</span>
                <span v-else>{{ $t("tests.doDone") }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col class="px-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    block
                    @click="refreshTable"
                    color="primary"
                  >
                    <v-icon> mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("refresh") }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col class="px-1" v-if="header.isDone">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" block @click="print">
                    <v-icon> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("print") }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- </v-card-subtitle> -->
      <v-card-text>
        <v-card flat v-if="!header.useTemplate">
          <v-data-table
            :items="bodies"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
            @click:row="rowClick($event)"
          >
            <template v-slot:[`item.testResult`]="{ item }">
              <v-text-field
                style="min-width: 100px"
                :ref="item.guid"
                :disabled="header.isDone"
                dense
                hide-details
                v-model="item.testResult"
                filled
                outlined
              ></v-text-field>
            </template>
            <template v-slot:[`item.modifyDate`]="{ item }">
              {{ item.modifyDate | moment("YYYY/MM/DD hh:mm A") }}
            </template>
          </v-data-table>
        </v-card>

        <div v-else>
          <Quill
            :prop.sync="templateContent"
            :myContent="templateContent"
          ></Quill>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="$store.getters.isInRole(98)"
          block
          color="primary white--text"
          @click="save"
          :disabled="header.isDone"
          >{{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm-dialog
      :openDialog="dialogDone"
      :onClicked="doneItemConfirm"
      :onClose="closeDone"
      :dialogSubtitle="
        !header.isDone ? $t('tests.doDone') : $t('tests.removeDone')
      "
      :dialogTitle="$t('tests.changeDone')"
    />
  </v-container>
</template>

<script>
import PatientDetail from "../../../components/PatientDetail.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

import Quill from "../../../components/Quill.vue";

export default {
  components: {
    PatientDetail,
    ConfirmDialog,
    Quill,
  },
  data() {
    return {
      bodies: [],
      testTemplates: [],
      header: {},
      templateContent: "",
      dialogDone: false,
    };
  },

  computed: {
    headers() {
      var list = [
        { text: this.$t("tests.parameterName"), value: "parameterName" },
        { text: this.$t("tests.parameterCode"), value: "parameterCode" },
        { text: this.$t("tests.unit"), value: "unit" },
        { text: this.$t("tests.machineTitle"), value: "machineTitle" },
        { text: this.$t("tests.testResult"), value: "testResult" },
        { text: this.$t("tests.normalRange"), value: "normalRange" },
        { text: this.$t("users.userName"), value: "modifyUserName" },
        { text: this.$t("modifyDate"), value: "modifyDate" },
      ];
      return list;
    },
    myContent() {
      return JSON.parse(JSON.stringify(this.templateContent));
    },
  },
  created() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.$axios
        .get("TestHeader/GetBodies?guid=" + this.$route.params.guid)
        .then((response) => {
          this.header = response.data.data;
          this.bodies = response.data.data.testBodies;
          // console.log(response.data.data);
          this.$axios
            .get(
              "TestTemplate?particularGuid=" + this.header.testParticularGuid
            )
            .then((response) => {
              this.testTemplates = response.data.data;
              var template = this.testTemplates.find(
                (x) => x.guid == this.header.testTemplateGuid
              );
              var res = this.header.testBodies[0];
              if (res && res.testResult) {
                this.templateContent = res.testResult;
              } else if (template) {
                this.templateContent = template.templateContent;
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        });
    },
    rowClick(e) {
      this.$refs[e.guid].focus();
    },

    templateChange(e) {
      var temp = this.testTemplates.find((element) => element.guid == e);
      if (temp) {
        this.templateContent = temp.templateContent;
      } else {
        this.templateContent = "";
      }
    },
    print() {
      // console.log(this.header);
      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "blob",
      };
      var data = {
        collectionId: this.header.collectionId,
        selectedHeader: this.header.guid,
      };
      this.$axios
        .post("TestProfileRequest/Print", data, postConfig)
        .then((response) => {
          if (response.status == 200) {
            var file = new Blob([response.data], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.$toast.error("Some thing went wrong");
          }
        })
        .catch((e) => {
          this.$toast.error("Some thing went wrong");
          console.log(e);
        });
    },
    save() {
      if (this.header.useTemplate) {
        // console.log(this.header.testBodies);
        if (this.header.testBodies && this.header.testBodies.length > 0) {
          this.header.testBodies[0].testResult = this.templateContent;
        } else {
          this.header.testBodies = [
            {
              testResult: this.templateContent,
              parameterCode: "TR",
              parameterName: "TemplateResult",
              seq: 1,
            },
          ];
        }
      }

      this.$axios
        .post("TestHeader/UpdateBodies", this.header)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.refreshTable();
        });
    },
    doneItem() {
      this.dialogDone = true;
    },
    closeDone() {
      this.dialogDone = false;
    },

    doneItemConfirm() {
      this.$axios
        .get("TestHeader/Done?guid=" + this.header.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
            this.refreshTable();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDone();
    },
  },
};
</script>

<style></style>
