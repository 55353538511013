<template>
  <div id="app">
    <vue-editor
      :customModules="customModulesForEditor"
      :editorOptions="editorSettings"
      v-model="content"
      @text-change="textChange"
    >
    </vue-editor>
  </div>
</template>

<script>
var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["link", "image", "video"], // link and image, video

  ["clean"], // remove formatting button

  [
    {
      custom: ["custom1", "custom2", "custom3"],
    },
  ], // custom button
];
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";

import QuillBetterTable from "quill-better-table";

//const quillTable = require("quill-table");
export default {
  props: ["temp", "myContent"],
  components: {
    VueEditor,
  },
  data() {
    return {
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize },
        { alias: "table", module: QuillBetterTable },
      ],
      editorSettings: {
        modules: {
          toolbar: toolbarOptions,
          imageDrop: true,
          imageResize: {},
        },
      },
      content: this.myContent,
    };
  },
  methods: {
    textChange() {
      this.$emit("update:prop", this.content);
    },
  },

  watch: {
    myContent(newVal) {
      //console.log(newVal);
      this.content = newVal;
    },
  },
};
</script>
