<template>
  <v-card outlined>
    <v-expansion-panels flat v-model="panel">
      <v-expansion-panel v-if="patient">
        <v-expansion-panel-header>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-avatar size="40">
                <v-img
                  lazy-src="../assets/user-icon.svg"
                  aspect-ratio="1"
                  :src="
                    patient.pictureUrl
                      ? patient.pictureUrl
                      : require('@/assets/user-icon.svg')
                  "
                >
                </v-img>
              </v-avatar>
            </v-col>
            <!-- src="https://localhost:44364/images/hiclipart.com (1).png" -->
            <v-col class="text-capitalize ma-1 mt-1">
              {{ patient.patientName }}
              <br />
              <span class="grey--text subtitle-2">
                {{ patient.patientCode }}
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card outlined class="v-card-profile pb-2">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3" sm="3" style="text-align: center">
                  <v-avatar size="150">
                    <v-img
                      lazy-src="../assets/user-icon.svg"
                      aspect-ratio="1"
                      :src="
                        patient.pictureUrl
                          ? patient.pictureUrl
                          : require('@/assets/user-icon.svg')
                      "
                    >
                    </v-img>
                  </v-avatar>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                  sm="9"
                  style="margin-top: 10px"
                  class="text-capitalize"
                >
                  <v-row>
                    <v-col cols="12" md="5" sm="6">
                      <v-icon class="grey--text ma-2">
                        mdi-alert-circle-outline</v-icon
                      >
                      <v-btn
                        min-width="0"
                        :to="'/patientregistration/' + patient.guid"
                        target="_blank"
                        class="pa-0"
                        color="primary"
                        text
                        >{{ patient.patientName }}</v-btn
                      >
                      <br />
                      <span>
                        <v-icon class="grey--text ma-2">
                          mdi-card-account-details-outline</v-icon
                        >
                        {{ patient.patientCode }}
                      </span>
                      <br />
                      <v-icon class="grey--text ma-2">
                        mdi-gender-male-female</v-icon
                      >
                      {{ getGender(patient.patientGender) }}
                      <br />
                      <span>
                        <v-icon class="grey--text ma-2">
                          mdi-cake-variant</v-icon
                        >
                        {{ (" " + patient.patientDob) | moment("YYYY/MM/DD") }}
                        <br />
                        <v-icon class="grey--text ma-2">
                          mdi-phone-outline</v-icon
                        >
                        {{ patient.patientMobile }}
                      </span>
                      <br />
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <span>
                        <v-icon class="grey--text ma-2">
                          mdi-map-marker-outline</v-icon
                        >
                        <span
                          dir="auto"
                          v-if="patient && patient.city && patient.city.country"
                          >{{ patient.city.country.countryName }},
                          {{ patient.city.cityName }}</span
                        >
                      </span>
                      <br />
                      <v-icon class="grey--text ma-2"> mdi-car-info</v-icon>
                      {{ patient.patientAddress }}
                      <br />
                      <span>
                        <v-icon class="grey--text ma-2">
                          mdi-briefcase-outline</v-icon
                        >
                        {{ patient.patientWork }}
                      </span>
                      <br />
                    </v-col>
                    <v-col cols="12" md="3" v-if="bills.length > 0">
                      <v-btn
                        target="_blank"
                        color="primary"
                        class="mx-2"
                        @click="openBill"
                      >
                        <v-icon> mdi-receipt</v-icon>
                        {{ $t("openBill") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-dialog v-model="billDialog" max-width="500">
            <v-card class="text-center">
              <v-card-title>
                {{ $t("bills.selectBill") }}
                <div class="text-end" style="width: 78%">
                  <v-btn icon color="red darken-2" @click="billDialog = false">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider />
              <v-card-text class="mt-3">
                <v-btn
                  v-for="(bill, i) in bills"
                  :key="i"
                  target="_blank"
                  color="primary"
                  class="mx-2"
                  :to="'/billDetails/' + bill.guid"
                >
                  {{
                    (bill.sourceType == 0
                      ? $t("bills.billSource.procedure")
                      : $t("bills.billSource.test")) +
                    " " +
                    bill.billCode
                  }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  props: ["patientGuid"],

  data: () => ({
    panel: [],
    show: false,
    billDialog: false,
    type: "success",
    message: "Completed Successfully",
    appointments: [],
    patient: {},
    bills: {},
    gender: [
      { value: 0, text: "patients.male" },
      { value: 1, text: "patients.female" },
    ],
  }),
  created() {},

  watch: {
    patientGuid(val) {
      this.$axios.get("Patient?guid=" + val).then((response) => {
        this.patient = response.data.data.patient;
        this.bills = response.data.data.bill;
        //console.log(response.data.data.bill);
        //console.log(this.bill);
      });
    },
  },
  methods: {
    getGender(i) {
      var x = this.gender.filter((o) => {
        return o.value == i;
      })[0];
      if (x) {
        return this.$t(x.text);
      }
    },
    openBill() {
      if (this.bills.length == 1) {
        let routeData = this.$router.resolve({
          name: "bills.billDetails",
          params: { guid: this.bills[0].guid },
        });
        window.open(routeData.href, "_blank");
        //this.$router.go("/billDetails/" + this.bill[0].guid);
      } else {
        this.billDialog = true;
      }
    },
  },
};
</script>

<style></style>
